import React, { useState, useEffect, useCallback } from "react";
import { graphql } from "gatsby";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { get } from "lodash";
import PropTypes from "prop-types";
import { Button, notification } from "antd";

import Pages from "../../components/Pages";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import seoData from "../../components/SEO/data";
import { ContentBlock, TextBlock } from "../../components/Blocks";
import { makeUrl, removeEmptyKeys, removePreloader } from "../../helpers";
import { SlideNews } from "../../components/Carousel/Slide";
import { Share } from "../../widgets";
import { showMore } from "../../constants";
import { useThemeContext } from "../../components/Layout/ThemeContext";
import { getBreadcrumbContext } from "./getBreadcrumbContext";

import { CONTENT_NEWS, CONTENT_NEWS_AGGREGATE } from "../../queries/queries.graphql";

export const query = graphql`
	query contentNewsQuery($slug: String!, $pagePath: String!)  {
		hasura {
			...ContentNews
			...PageSettings
		}
	}
`;

export default function ContentNews({ data, pageContext }) {
	const label = "Новости";
	const title = "Все новости";
	const limit = 9;
	const url = makeUrl.contentNews(pageContext);
	const entity = data?.hasura?.page_settings?.[0] || get(seoData, "all_news", {});
	const breadcrumbContext = getBreadcrumbContext(pageContext);
	const initialNews = get(data, "hasura.v_content_news", []);

	const { theme } = useThemeContext();
	const { slug } = pageContext;
	const [offset, setOffset] = useState(null);

	const {
		loading: aggregateLoading,
		error: aggregateError,
		data: newsAggregate,
	} = useQuery(CONTENT_NEWS_AGGREGATE, {
		variables: {
			slug,
		},
	});

	const count = get(newsAggregate, "v_content_news_aggregate.aggregate.count", 0);

	const makeData = useCallback(data => data.map(({ news }, idx) => {
		const { page_title_short, page_title_full, main_image_preview, main_image, issued_at } = news;

		const path = makeUrl.news(news);
		const image = get(main_image_preview, "src", "") || get(main_image, "src", "");
		const title = page_title_short || page_title_full;
		const date = new Date(issued_at).toLocaleDateString();

		return (
			<div key={idx} className={"col-xs-12 col-md-4 mb-4"}>
				<SlideNews path={path} src={image} title={title} date={date} mediaSize={["100%", 400]} />
			</div>
		);
	}), [makeUrl]);

	const [contentNews, setContentNews] = useState(makeData(initialNews));

	const [loadNews, { called, loading, error, data: contentNewsData }] = useLazyQuery(
		CONTENT_NEWS,
		{
			variables: {
				slug,
			},
			partialRefetch: true,
		});

	useEffect(() => {
		if (called && !error && !loading) {
			setContentNews(contentNews => {
				const newStateBlank = [...contentNews, ...makeData(get(contentNewsData, "v_content_news", []))];

				setOffset(newStateBlank.length >= count ? null : offset + limit);

				return newStateBlank;
			});
		} else if (error) {
			setOffset(null);
		}

		removePreloader(!loading || error || aggregateLoading);
	}, [loading, contentNewsData, called]);

	useEffect(() => {
		if (!aggregateError && initialNews.length < count) {
			setOffset(limit);
		}

		if (aggregateError) {
			console.error("aggregate Error on ContentNews");
			notification.error({
				message: "Ошибка загрузки данных",
			});
		}
	}, [count, aggregateError]);

	const onLoadMore = useCallback(() => {
		loadNews({
			variables: {
				offset,
			},
		});
	}, [offset]);

	return (
		<Pages
			url={url}
			entity={entity}
		>
			<ContentBlock key={"breadcrumbs"}>
				<div className="container">
					<Breadcrumbs currentLabel={label} pageContext={breadcrumbContext} />
				</div>
			</ContentBlock>
			<ContentBlock key={"main-content"}>
				<div className={"container"}>
					<TextBlock title={title}>
						<div className={"row"}>
							{contentNews}
						</div>
					</TextBlock>
					<div className="row justify-content-center mb-5">
						<Button
							type={"primary"}
							disabled={loading || !offset || aggregateLoading}
							onClick={onLoadMore}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.text,
								lineHeight: theme.lineHeight.text,
								color: theme.color.body,
								backgroundColor: theme.color.text,
							})}
							{...{ loading }}
						>
							{showMore}
						</Button>
					</div>
				</div>
			</ContentBlock>
			<div className={"container"}>
				<Share url={url} pageTitleShort={title} />
			</div>
		</Pages>
	);
}

ContentNews.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
};

ContentNews.defaultProps = {
	data: {},
	pageContext: {
		slug: "",
	},
};
