import get from "lodash/get";

export const getBreadcrumbContext = pageContext => {
	switch (get(pageContext, "type", null)) 	{
		case "city":
			return {
				item: {
					title_full: get(pageContext, "title_full", ""),
					slug: get(pageContext, "slug", ""),
				},
			};
		case "monastery":
		case "city_object":
		case "museum":
			return {
				item: {
					title_full: get(pageContext, "city.title_full", ""),
					slug: get(pageContext, "city.slug", ""),
				},
				item2: {
					title_full: get(pageContext, "title_full", ""),
					slug: get(pageContext, "slug", ""),
				},
			};
		case "belltower":
		case "chapel":
		case "church":
		case "hermitage":
		case "temple":
		case "well":
			return {
				item: {
					title_full: get(pageContext, "city.title_full", ""),
					slug: get(pageContext, "city.slug", ""),
				},
				item1: {
					title_full: get(pageContext, "monastery.title_full", ""),
					slug: get(pageContext, "city.slug", ""),
				},
				item2: {
					title_full: get(pageContext, "title_full", ""),
					slug: get(pageContext, "slug", ""),
				},
			};

		default:
			return [];
	}
};
